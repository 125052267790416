import { CircularProgress } from '@material-ui/core';
import * as React from 'react';
import { useParams } from 'react-router';
import { loadLink } from '../../service/link/link-service';

import './home.scss'


function Home() {
    const { code } = useParams()
    const [link, setLink] = React.useState('')
    const [searched, setSearched] = React.useState(false)
    const [spinnerVisible, setSpinnerVisible] = React.useState(true)
    const [containerClass, setContainerClass] = React.useState('container-home')

    React.useEffect(() => {
        loadReceivedLink()
        setTimeout(() => {
            setSpinnerVisible(false)
        }, 5000)
    }, [code])

    const loadReceivedLink = () => {
        if (code) {
            loadLink(code).then(res => {
                if (res.data) {
                    setLink(res.data.description)
                } else {
                    setLink(false)
                }
            })
            setSearched(true)
            setContainerClass('container-home full-height')
        }
    }

    if (link) {
        return window.location.replace(link)
    }

    if (code && spinnerVisible) {
        return (
            <div className="container-loading col-12">
                <div>
                    <CircularProgress />
                </div>
            </div>
        )
    }

    if (searched) {
        return (
            <div className={`${containerClass} col-lg-12 centered`}>
                <div>
                    <span className="not-found">Link não encontrado!</span>
                </div>
            </div>
        )
    } else {
        if (code) {
            return (
                <div className={`${containerClass} col-lg-12`}>
                    <div>
                        <span className="title-two">Carregando...</span>
                    </div>
                </div>
            )
        } else {
            return (
                <div className={`${containerClass} col-lg-12`}>
                    <div>
                        <span className="title-two">Clamed</span>
                        <span className="title-two">URL</span>
                        <span className="title-two">Shortner</span>
                    </div>
                </div>
            )
        }
    }
}

export default Home