import * as React from "react";
import { CSVLink } from "react-csv";

import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import UploadIcon from "@material-ui/icons/CloudUpload";
import ExportIcon from "@material-ui/icons/GetApp";

import LinkListItem from "./link-list-item/link-list-item";
import LinkForm from "./link-form/link-form";

import { getAllLinks } from "../../service/link/link-service";

import "./links.scss";
import ModalDeleteLink from "./link-list-item/modal-delete-link/modal-delete-link";
import UploadForm from "./upload-form/upload-form";
import { validarLogin } from '../../service/user/user-service'
import { isConstructorDeclaration } from "typescript";

function Links() {
  const [links, setLinks] = React.useState([]);
  const [linkId, setLinkId] = React.useState();
  const [modalLinkOpened, setModalLinkOpened] = React.useState(false);
  const [modalDeleteLinkOpened, setModalDeleteLinkOpened] = React.useState(false);
  const [modalUploadOpened, setModalUploadOpened] = React.useState(false);
  const csvLinkRef = React.useRef();
  const [nrMatricula, setnrMatricula] = React.useState();
  const [cdLocalRhSenior, setCdLocalRhSenior] = React.useState();

  const headers = [
    { label: "Código", key: "id" },
    { label: "Nome", key: "name" },
    { label: "URL", key: "description" },
    { label: "Marca", key: "type" },
    { label: "Link", key: "link" },
  ];

  React.useEffect(() => {
    loadLinks();
    getData();
  }, []);

  const loadLinks = () => {
    getAllLinks().then((res) => {
      setLinks(res.data);
    });
  };

  const openLinkForm = () => {
    setLinkId(undefined);
    setModalLinkOpened(true);
  };

  const openUploadForm = () => {
    setModalUploadOpened(true);
  };

  const closeUploadForm = () => {
    setModalUploadOpened(false);
    loadLinks();
  };

  const closeLinkForm = () => {
    setModalLinkOpened(false);
    loadLinks();
  };

  const onClickEditLink = (id) => {
    setLinkId(id);
    setModalLinkOpened(true);
  };

  const closeModalDeleteLink = () => {
    setModalDeleteLinkOpened(false);
    loadLinks();
  };

  const onClickDeleteLink = (id) => {
    setLinkId(id);
    setModalDeleteLinkOpened(true);
  };

  const exportData = () => {
    csvLinkRef.current.link.click();
  };

  const exportLinks = () => {
    return links.map((item) => {
      return { ...item, link: `http://links${item.type}.com/${item.id}` };
    });
  };

  const getData = () => {
    let mat     = localStorage.getItem("matricula")
    let ccRhSr  = localStorage.getItem("ccRhSr")
    
    setnrMatricula( mat )
    setCdLocalRhSenior( ccRhSr )
  }

  return (
    <div className="container-links">
      <UploadForm opened={modalUploadOpened} handleClose={closeUploadForm} />
      <LinkForm
        opened={modalLinkOpened}
        handleCloseModal={closeLinkForm}
        id={linkId}
        nrMatricula={nrMatricula}
        cdLocalRhSenior={cdLocalRhSenior}
      />
      <ModalDeleteLink
        handleClose={closeModalDeleteLink}
        opened={modalDeleteLinkOpened}
        id={linkId}
      />

      <CSVLink
        headers={headers}
        filename="ClamedShortLinks.csv"
        data={exportLinks()}
        ref={csvLinkRef}
      />

      <div className="page-actions">
        <div className="col-2 no-padding">
          <h1>Links</h1>
        </div>
        <div className="col-10 text-right no-padding">
          <Fab
            size="small"
            color="primary"
            aria-label="add"
            onClick={openLinkForm}
          >
            <AddIcon />
          </Fab>

          <Fab
            size="small"
            color="primary"
            aria-label="upload"
            onClick={openUploadForm}
            style={{ marginLeft: 10, marginRight: 10 }}
          >
            <UploadIcon />
          </Fab>

          <Fab
            size="small"
            color="primary"
            aria-label="upload"
            onClick={exportData}
          >
            <ExportIcon />
          </Fab>
        </div>
      </div>
      {links.length == 0 && (
        <div className="no-items">
          <span>
            Não foi encontrado nenhum registro, clique em + para adicionar um
            novo link
          </span>
        </div>
      )}
      {links.length > 0 &&
        links.map((link) => (
          <LinkListItem
            key={link.id}
            link={link}
            onClickEditLink={onClickEditLink}
            onClickDeleteLink={onClickDeleteLink}
          />
        ))}
    </div>
  );
}

export default Links;
