import * as React from 'react';
import { useEffect } from 'react';
import { Redirect } from 'react-router'

import { validarLogin, resendConfirmation } from '../../service/user/user-service'

import './login.scss'


function Login() {
    const [email, setEmail] = React.useState()
    const [pwd, setPwd] = React.useState()

    const [redirectToHome, setRedirectToHome] = React.useState(false)
    const [redirectToRegister, setRedirectToRegister] = React.useState(false)

    const [error, setError] = React.useState(false)
    const [userMailNotConfirmed, setUserMailNotConfirmed] = React.useState(false)

    useEffect(() => {
        localStorage.removeItem('token')
    }, [])

    const onFormSubmit = (e) => {
        e.preventDefault()

        validarLogin(email, pwd)
            .then(res => {
                if (res.data.success && res.data.data)
                {
                    const token = res.data.token
                    localStorage.setItem('token', token)
                    localStorage.setItem( 'matricula', res.data.data.nr_matricula )
                    localStorage.setItem( 'ccRhSr', res.data.data.cd_local_rh_senior )
                    setRedirectToHome(true)
                }
                else
                {
                    setError('Não foi possível realizar login! Verifique se o usuário e senha informados foram digitados corretamente e tente novamente.')
                }
            })
            .catch(e => {
                if (e.response.status == 403) {
                    setUserMailNotConfirmed(true)
                } else {
                    setError('Não foi possível realizar login! Verifique se o usuário e senha informados foram digitados corretamente e tente novamente.')
                }
            })
    }

    const onClickResendConfirmationLink = () => {
        resendConfirmation(email).then(res => {
            setRedirectToRegister(true)
        })
    }

    if (redirectToHome) {
        return <Redirect to='/' />;
    }

    if (redirectToRegister) {
        return <Redirect to='/page/register?done=true' />;
    }

    return (
        <div className="centered">
            <div className="container col-md-6 col-lg-4">
                <form onSubmit={onFormSubmit}>
                    <h3>Clamed URL Shortner</h3>

                    <div className="form-group text-left">
                        <label>Matrícula</label>
                        <input className="form-control inputAuto" type="text" onChange={(e) => setEmail(e.target.value)} placeholder="Informe sua matrícula" />
                    </div>

                    <div className="form-group text-left">
                        <label>Senha</label>
                        <input className="form-control inputAuto" type="password" onChange={(e) => setPwd(e.target.value)} placeholder="Informe sua senha" />
                    </div>

                    {/* <div className="form-group inputAuto text-left">
                        <div className="custom-control custom-checkbox">
                            <input type="checkbox" className="custom-control-input" id="customCheck1" />
                            <label className="custom-control-label" htmlFor="customCheck1">Lembrar me</label>
                        </div>
                    </div>
                    
                    <p className="forgot-password text-center inputAuto no-padding">
                        Esqueceu sua senha?<a href="#"> Recuperar</a>
                    </p>

                    {userMailNotConfirmed && (
                        <div className="error">
                            <span >Parece que você ainda não confirmou seu email. Verifique sua caixa de entrada e clique no link de confirmação. Caso não tenha recebido o link verifique sua caixa de spam ou </span>
                            <a href='#' onClick={onClickResendConfirmationLink}>clique aqui para enviar novamente.</a>
                        </div>
                    )} */}

                    {error && (
                        <div className="error">
                            <span >{error}</span>
                        </div>
                    )}

                    <button type="submit" className="btn btn-primary btn-block inputAuto col-8">Entrar</button>
                </form>
            </div>
        </div>
    )
}

export default Login