import React from 'react'
import { Nav, Navbar } from "react-bootstrap";
import { Link, useLocation } from 'react-router-dom'
import { isAuthenticated } from '../../auth'

import './navBar.scss'

function NavBar() {
    const location = useLocation();

    return (location.pathname == '/page/links' || location.pathname == '/') && (
        <div className='navBar'>
            <Navbar className="w-auto p-3 fixed-top menu" collapseOnSelect expand="lg" variant="dark">
                <Navbar.Brand className="brand-link" as={Link} to="/">Clamed URL Shortner</Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav className="mr-auto">

                    </Nav>
                    <Nav>
                        {isAuthenticated() && <Nav.Link as={Link} to="/page/links">Links</Nav.Link>}
                        <Nav.Link as={Link} to="/page/logout">{isAuthenticated() ? 'Logout' : 'Login'}</Nav.Link>
                    </Nav>
                </Navbar.Collapse>
            </Navbar>
        </div>
    )
}

export default NavBar