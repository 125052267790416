import * as React from 'react';
import { Route } from 'react-router-dom'
import { Redirect } from 'react-router'
import { isAuthenticated } from '../../auth'

const PrivateRoute = ({ component: Component, ...rest }) => {
    return (
        <Route {...rest} render={(props) => {
            if (isAuthenticated()) {
                return (
                    <Component {...props} />
                )
            }
            return (
                <Redirect to='/page/login' />
            )
        }} />
    )
}

export default PrivateRoute