import * as React from 'react';
import { useLocation } from 'react-router-dom';

import { verify } from '../../service/user/user-service'

import './verify.scss';


function Verify() {
    const location = useLocation();
    const token = new URLSearchParams(location.search).get('token')

    React.useEffect(() => {
        verify(token)
            .then(res => {
                console.log('success')
            })
            .catch(error => {
                console.log(error)
            })
    })

    return (
        <div className="centered">
            <div className="container col-md-6 col-lg-4">
                <h3>Confirmação de Email</h3>
                <div>
                    <h2>Email confirmado com sucesso!</h2>

                    <p className="forgot-password text-center inputAuto no-padding">
                        Deseja realizar o login? <a href="/page/login">Entrar</a>
                    </p>
                </div>
            </div>
        </div>
    )
}

export default Verify