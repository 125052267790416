import React, { useState } from 'react';
import QRCode from 'react-qr-code';

import DeleteForeverIcon from '@material-ui/icons/DeleteForeverRounded';
import EditIcon from '@material-ui/icons/EditRounded';
import SaveAlt from '@material-ui/icons/SaveAlt';

import ContentCopyIcon from '@material-ui/icons/FileCopyRounded';
import { Snackbar } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';

import "./link-list-item.scss"

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function LinkListItem({ link, onClickEditLink, onClickDeleteLink }) {
    const [open, setOpen] = useState(false);

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };

    const onClickCopyLinkDC = () => {
        navigator.clipboard.writeText('http://linksdc.com/' + link.id.toUpperCase())
        setOpen(true);
    }

    const onClickCopyLinkPP = () => {
        navigator.clipboard.writeText('http://linkspp.com/' + link.id.toUpperCase())
        setOpen(true);
    }

    const getURL = (link) => `http://links${link.type}.com/${link.id}`

    return (
        <div className="idea-box" >

            <div id="containerQr">
                <QRCode value={getURL(link)} size={100} />
            </div>
            <div className="fields">
                <span className="link-type" >{link.type == 'dc' ? 'Drogaria Catarinense' : 'Farmácia Preço Popular'}</span>
                <span className="link-title" >{`Nome: ${link.name}`}</span>
                <span>URL Originals: <a href={link.description}>{`${link.description}`}</a></span>
                <span className="link-title" >URL Curta: <a href={getURL(link)}>{getURL(link)}</a></span>
            </div>
            <Snackbar open={open} autoHideDuration={1000} onClose={handleClose}>
                <Alert onClose={handleClose} severity="success">
                    Link copiado para sua área de transferência.
                </Alert>
            </Snackbar>
            <div className="actions">
                <a id="btnQrCode" href={ `http://api.qrserver.com/v1/create-qr-code/?data=${getURL(link)}&size=500x500&margin=15` } target="_blank" title="Baixar QR Code" download >
                    <SaveAlt className="icon-default" />
                </a>
                <EditIcon className="icon-default" onClick={() => onClickEditLink(link.id)} />
                <DeleteForeverIcon className="icon-default" onClick={() => onClickDeleteLink(link.id)} />
                {link.type == 'dc' && (<ContentCopyIcon className="copy-dc" onClick={() => onClickCopyLinkDC(link.id)} />)}
                {link.type == 'pp' && (<ContentCopyIcon className="copy-pp" onClick={() => onClickCopyLinkPP(link.id)} />)}
            </div>

        </div>
    )
}

export default LinkListItem