import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import TextField from '@material-ui/core/TextField';
import CloseIcon from '@material-ui/icons/CloseRounded'
import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';
import { createLink, getLinkById, updateLink } from '../../../service/link/link-service';
import './link-form.scss'

const useStyles = makeStyles((theme) => ({
    paper: {
        position: 'absolute',
        width: '80%',
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
    input: {
        marginBottom: 20,
        paggingTop: 10
    }
}));

function getModalStyle() {
    const top = 50;
    const left = 50;

    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`,
    };
}

export default function LinkForm({ opened, handleCloseModal, id, nrMatricula, cdLocalRhSenior }) {
    const classes = useStyles();
    const isEditing = id !== undefined

    const [title, setTitle] = useState('')
    const [description, setDescription] = useState('')
    const [type, setType] = useState('')

    React.useEffect( () => { loadLink() }, [id] )

    const loadLink = () => {
        if (id) {
            getLinkById(id).then(res => {
                setTitle(res.data.name)
                setDescription(res.data.description)
                setType(res.data.type)
            })
        } else {
            setTitle('')
            setDescription('')
            setType('')
        }
    }

    const handleClose = () => {
        setTitle('')
        setDescription('')
        setType('')
        handleCloseModal()
    }

    const onClickSalvar = () => {
        let Titulo      = document.querySelector("#inptTitulo").value
        let UrlOriginal = document.querySelector("#inptUrlOriginal").value
        let Marca       = document.querySelector("#inptMarca").innerHTML

        if ( Titulo == "" )
        {
            alert( "Campo Título inválido!" )
        }
        else if ( UrlOriginal == "" )
        {
            alert( "Campo URL Original inválido!" )
        }
        else if ( (Marca != "Drogaria Catarinense" && Marca != "Farmácia Preço Popular") )
        {
            alert( "Campo Marca inválido!" )
        }
        else
        {
            if (isEditing) {
                updateLink( id, title, description, type, nrMatricula, cdLocalRhSenior )
                .then(() => {
                    alert('Link alterada com sucesso!')
                    handleClose()
                })
            }
            else
            {
                createLink(undefined, title, description, type, nrMatricula, cdLocalRhSenior)
                .then(() => {
                    alert('Link criada com sucesso!')
                    handleClose()
                })
            }
        }
    }

    return (
        <Modal
            open={opened}
            onClose={handleClose}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
        >
            <div style={getModalStyle()} className={classes.paper}>
                <div className="actions">
                    <CloseIcon onClick={() => handleClose()} />
                </div>
                <h1>Cadastre seu link</h1>
                <form noValidate autoComplete="off">
                    <TextField id="inptTitulo" label="Título" value={title} onChange={(e) => { setTitle(e.target.value) }} fullWidth />
                    <TextField id="inptUrlOriginal" label="URL Original" InputProps={{ className: classes.input }} value={description}
                        onChange={(e) => { setDescription(e.target.value) }} fullWidth multiline rows={5} />
                    <FormControl className={classes.formControl} fullWidth required>
                        <Select
                            id="inptMarca"
                            value={type}
                            onChange={(e) => { setType(e.target.value) }}
                            displayEmpty
                        >
                            <MenuItem value='' disabled>
                                Marca
                            </MenuItem>
                            <MenuItem value={'dc'}>Drogaria Catarinense</MenuItem>
                            <MenuItem value={'pp'}>Farmácia Preço Popular</MenuItem>
                        </Select>
                    </FormControl>
                    <div className="actions">
                        <button type="submit" className="btn btn-primary btn-block col-2" onClick={onClickSalvar}>Salvar</button>
                    </div>
                </form>
            </div>
        </Modal>
    )
}