import * as React from 'react';
import { useEffect } from 'react';
import { Redirect } from 'react-router-dom';

const Logout = () => {

    useEffect(() => {
        localStorage.removeItem('token')
    }, [])

    return (
        <Redirect to={{ pathname: '/page/login' }} />
    )
}

export default Logout