import * as React from 'react';
import { useLocation } from 'react-router-dom';
import { register } from '../../service/user/user-service';
import './register.scss';


function Register() {
    const location = useLocation();
    const done = new URLSearchParams(location.search).get('done')

    const [name, setName] = React.useState()
    const [email, setEmail] = React.useState()
    const [pwd, setPwd] = React.useState()

    const [error, setError] = React.useState()
    const [hasDone, setHasDone] = React.useState(done)

    const onFormSubmit = (e) => {
        e.preventDefault()

        register(name, email, pwd)
            .then(res => {
                setHasDone(true)
            })
            .catch(e => {
                setError(e.response.data.error)
            })
    }

    return (
        <div className="centered">
            <div className="container col-md-6 col-lg-4">
                <h3>{hasDone ? 'Confirmação de email' : 'Cadastrar'}</h3>

                {hasDone && (
                    <div>
                        <h2>Email de confirmação enviado! Verifique sua caixa de entrada para confirmar sua identidade e em seguida você poderá realizar login.</h2>

                        <p className="forgot-password text-center inputAuto no-padding">
                            Já confirmou seu email? <a href="/page/login">Entrar</a>
                        </p>
                    </div>
                )}

                {!hasDone && !done && (
                    <form onSubmit={onFormSubmit}>
                        <div className="form-group text-left">
                            <label>Nome</label>
                            <input type="text" onChange={(e) => setName(e.target.value)} className="form-control inputAuto" placeholder="Informe seu nome completo" />
                        </div>

                        <div className="form-group text-left">
                            <label>Email</label>
                            <input type="email" onChange={(e) => setEmail(e.target.value)} className="form-control inputAuto" placeholder="Informe seu email" />
                        </div>

                        <div className="form-group text-left">
                            <label>Senha</label>
                            <input type="password" onChange={(e) => setPwd(e.target.value)} className="form-control inputAuto" placeholder="Informe sua senha" />
                        </div>

                        {error && (
                            <p className="error">
                                {error}
                            </p>
                        )}

                        <p className="forgot-password text-center inputAuto no-padding">
                            Já possui cadastro? <a href="/page/login">Entrar</a>
                        </p>

                        <button type="submit" className="btn btn-primary btn-block inputAuto col-8">Cadastrar</button>
                    </form >
                )}
            </div>
        </div>
    )
}

export default Register