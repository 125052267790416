import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { CSVReader } from 'react-papaparse';

import Modal from '@material-ui/core/Modal';
import CloseIcon from '@material-ui/icons/CloseRounded'

import { createLink, deleteAllLinks, updateLink } from '../../../service/link/link-service';

import './upload-form.scss'
import { FormControlLabel, Switch, TableContainer, Table, TableBody, TableRow, TableCell, Paper, TableHead } from '@material-ui/core';


const useStyles = makeStyles((theme) => ({
    paper: {
        position: 'absolute',
        width: '80%',
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3)
    },
    input: {
        marginBottom: 20,
        paggingTop: 10
    },
    tablePaper: {
        marginBottom: 20,
        marginTop: 20,
        maxHeight: '40vh'
    },
    table: {

    }
}));

function getModalStyle() {
    const top = 50;
    const left = 50;

    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`,
    };
}

export default function UploadForm({ opened, handleClose }) {
    const classes = useStyles();

    const [overwrite, setOverwrite] = useState(false)
    const [ignoreHeader, setIgnoreHeader] = useState(false)
    const [data, setData] = useState([])

    const onClickSalvar = async () => {
        if (overwrite) {
            await deleteAllLinks().then(async (res) => {
                await createLinks()
                alert('Dados atualizados com sucesso!')
                handleClose()
            })

        } else {
            await createLinks()
            alert('Dados atualizados com sucesso!')
            handleClose()
        }

    }

    const createLinks = async () => {
        const filteredData = ignoreHeader ? data.slice(1, data.length) : data
        await filteredData.forEach((item) => {
            createLink(item.data[0], item.data[1], item.data[2], item.data[3]).then(() => {
            })
        })
    }

    const handleOnDrop = (data) => {
        setData(data)
    };

    const handleOnError = (err, file, inputElem, reason) => {
        console.log(err);
    };

    const handleOnRemoveFile = (data) => {
        setData(data)
    };

    const handleChangeOverwrite = (e) => {
        setOverwrite(e.target.checked)
    }

    const handleChangeIgnoreHeader = (e) => {
        setIgnoreHeader(e.target.checked)
    }

    const getTable = () => {
        if (data && data.length > 0) {
            const contentRows = data
            return (
                <TableContainer component={Paper} className={classes.tablePaper}>
                    <Table className={classes.table} size="small" aria-label="a dense table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Código</TableCell>
                                <TableCell>Nome</TableCell>
                                <TableCell align="right">URL</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {contentRows.map((row) => (
                                <TableRow key={row.name}>
                                    <TableCell component="th" scope="row">
                                        {row.data[0]}
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        {row.data[1]}
                                    </TableCell>
                                    <TableCell align="right">{row.data[2]}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            );
        }
    }

    return (
        <Modal
            open={opened}
            onClose={handleClose}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
        >
            <div style={getModalStyle()} className={classes.paper}>
                <div className="actions">
                    <CloseIcon onClick={() => handleClose()} />
                </div>
                <h1>Faça o upload dos seus links</h1>

                <CSVReader
                    onDrop={handleOnDrop}
                    onError={handleOnError}
                    addRemoveButton
                    onRemoveFile={handleOnRemoveFile}
                >
                    <span>Drop CSV file here or click to upload.</span>
                </CSVReader>

                {getTable()}

                <div className="actions">
                    <FormControlLabel
                        control={
                            <Switch
                                checked={ignoreHeader}
                                onChange={handleChangeIgnoreHeader}
                                name="ignoreHeader"
                                color="primary"
                            />
                        }
                        label='Ignorar header do arquivo'
                    />
                    <FormControlLabel
                        control={
                            <Switch
                                checked={overwrite}
                                onChange={handleChangeOverwrite}
                                name="overwrite"
                                color="primary"
                            />
                        }
                        label={overwrite ? 'Substituir registros existentes' : 'Atualizar apenas as diferenças'}
                    />
                    <button type="submit" className="btn btn-primary btn-block col-2" onClick={onClickSalvar}>Atualizar</button>
                </div>

            </div>
        </Modal>
    )
}