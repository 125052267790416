import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';

import { deleteLink } from '../../../../service/link/link-service';

const useStyles = makeStyles((theme) => ({
    paper: {
        position: 'absolute',
        width: 400,
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    }
}));

function getModalStyle() {
    const top = 50;
    const left = 50;

    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`,
    };
}

export default function ModalDeleteLink({ opened, handleClose, id }) {
    const classes = useStyles()

    const onClickConfirm = () => {
        deleteLink(id).then(() => {
            alert('Link removido com sucesso')
            handleClose()
        })
    }

    const onClickCancel = () => {
        handleClose()
    }

    return (
        <Modal
            open={opened}
            onClose={handleClose}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
        >
            <div className={classes.paper} style={getModalStyle()}>
                <h3>Tem certeza que deseja excluir o link?</h3>

                <button className="btn btn-block inputAuto col-8" onClick={onClickCancel}>Cancelar</button>
                <button className="btn btn-primary btn-block inputAuto col-8" onClick={onClickConfirm}>Excluir</button>
            </div>
        </Modal>
    )
}