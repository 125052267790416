import * as React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import './App.scss';
import NavBar from './components/navBar/navBar';
import PrivateRoute from './components/private-route/private-route';
import Home from './pages/home/home';
import Links from './pages/links/links';
import Login from './pages/login/login';
import Logout from './pages/logout/logout';
import Register from './pages/register/register';
import Sobre from './pages/sobre/sobre';
import Verify from './pages/verify/verify';

function App() {
  return (
    <div className="App">
      <Router>

        <NavBar />

        <Route exact path='/page/login' component={Login} />
        <Route exact path='/page/register' component={Register} />
        <Route exact path='/page/verify' component={Verify} />
        <PrivateRoute exact path='/page/links' component={Links} />
        <PrivateRoute exact path='/page/logout' component={Logout} />
        <Route exact path='/:code' component={Home} />
        <Route exact path='/' component={Home} />
        
      </Router>
    </div>
  );
}

export default App;
