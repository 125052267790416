const axios = require('axios')

function login(mail, password) {
    return axios.post('/api/user/token', { 'mail': mail, 'password': password })
}

function validarLogin(matricula, senha) {
    const headers = {
        'Content-Type': 'application/x-www-form-urlencoded',
        'Access-Control-Allow-Origin': '*'
    }

    var params = new URLSearchParams();
    params.append('token', 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1c2VyIjoic2VydmljZWNhcmUiLCJjb21wYW55IjoiQ2lhIExhdGlubyBBbWVyaWNhbmEgZGUgTWVkaWNhbWVudG9zIiwic2l0ZSI6Imh0dHBzOlwvXC93d3cuY2xhbWVkLmNvbS5iciIsImlhdCI6MTYxMDk3MTczNiwiZXhwIjoxNzY4NzM4MTM2fQ.jVMWkx_SDZZ5BCyqI_QXIExDxvjJN2J-GNQp7DjGMdo');
    params.append('type', 'login');
    params.append('q', matricula);
    params.append('q2', window.btoa(senha));    

    return axios({
        method: 'get',
        url: 'https://ws.clamed.com.br:8080/sincroniza.php',        
        params: params,
        headers: headers
    });
}

function verify(token) {
    return axios.post('/api/user/verify', { 'token': token })
}

function register(name, mail, password) {
    return axios.post('/api/user/register', { 'name': name, 'mail': mail, 'password': password, type: 2 })
}

function resendConfirmation(mail) {
    return axios.post('/api/user/resendConfirmation', { 'mail': mail })
}

module.exports = { login, register, resendConfirmation, verify, validarLogin }