const axios = require('axios');

function getAllLinks() {
    return axios.get('/api/links/')
}

function getLinkById(id) {
    return axios.get(`/api/links/${id}`)
}

function loadLink(id) {
    return axios.get(`/api/link/${id}`)
}

function createLink(id, name, description, type, nrMatricula, cdLocalRhSenior) {
    return axios.post('/api/links/', { id, name, description, type, nrMatricula, cdLocalRhSenior })
}

function updateLink(id, name, description, type, nrMatricula, cdLocalRhSenior) {
    return axios.put(`/api/links/${id}`, { name, description, type, nrMatricula, cdLocalRhSenior })
}

function deleteLink(id) {
    return axios.delete(`/api/links/${id}`)
}

function deleteAllLinks() {
    return axios.delete(`/api/links/`)
}

module.exports = {
    getAllLinks, getLinkById, loadLink, createLink, updateLink, deleteLink, deleteAllLinks
}